@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=VT323:wght@400;700&display=swap');

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;
 
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
 
    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;
 
    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;
 
    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;
 
    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;
 
    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}
 
@layer base {
  * {
    @apply border-border;
    font-family: 'VT323', sans-serif;
    font-size: 22px;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.wallet-adapter-dropdown-list-item {
  font-size: 1rem !important; /* This matches Tailwind's text-lg */
  line-height: 1.75rem !important;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgba(107, 114, 128, 0.3) transparent;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(107, 114, 128, 0.3);
  border-radius: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: rgba(107, 114, 128, 0.5);
}

/* Window scrollbar styles */
.overflow-y-auto {
  scrollbar-width: thin;
  scrollbar-color: #ef4444 rgba(0, 0, 0, 0.2);
}

.overflow-y-auto::-webkit-scrollbar {
  width: 6px;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  margin-right: 2px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background: var(--destructive);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.overflow-y-auto::-webkit-scrollbar-thumb:hover {
  background: color-mix(in srgb, var(--destructive) 80%, black);
}


.hacker-prompt-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: rgb(132, 204, 22) rgba(0, 0, 0, 0.2);
}

.hacker-prompt-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.hacker-prompt-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  margin-right: 2px;
}

.hacker-prompt-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(132, 204, 22);
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.hacker-prompt-scrollbar::-webkit-scrollbar-thumb:hover {
  background: color-mix(in srgb, rgb(132, 204, 22) 80%, black);
}